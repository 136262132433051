<template>
  <div class="home-container">
    <div class="moblie" style="height: 1.75rem"></div>
    <div class="f1" id="fangAn1">解决方案</div>
    <div class="pc" style="height: 0.44rem"></div>
    <div class="moblie" style="height: 1rem"></div>
    <common-swiper class="pc" :list="list"></common-swiper>
    <m-common-swiper class="moblie" :list="list"></m-common-swiper>
    <div class="moblie" style="height: 2.3rem"></div>
  </div>
</template>
<script>
import commonSwiper from "@/components/common-swiper";
import mCommonSwiper from "@/components/m-common-swiper";

export default {
  data() {
    return {
      list: [
        {
          image: require("@/assets/home/swiper/1.png"),
          mImage: require("@/assets/home/swiper/1m.png"),
          text: "贴标管理",
          imgOffset: "-65%",
          info: "标签是整个RFID服装智慧门店应用工作的基础,使用之前需要电子标签进行编码并与服装进行绑定,完成电子标签与服装信息的绑定,可以配套贴标机进行使用。",
          // icon: require("@/assets/wash/swiper2/icon1.png"),
          // icon1: require("@/assets/wash/swiper2/icon1c.png"),
        },
        {
          image: require("@/assets/home/swiper/2.png"),
          mImage: require("@/assets/home/swiper/2m.png"),
          text: "衣服盘点",
          imgOffset: "-29%",
          info: "利用RFID读取设备实现库存货品的快速盘点及高效交接,极大提升作业效率及盘点速度对库存数据精确性的掌握。",
          // icon: require("@/assets/wash/swiper2/icon2.png"),
          // icon1: require("@/assets/wash/swiper2/icon2c.png"),
        },
        {
          image: require("@/assets/home/swiper/3.png"),
          mImage: require("@/assets/home/swiper/3m.png"),
          text: "智能货架",
          imgOffset: "-28%",
          info: "应用超高频RFID自动识别技术,为每种每类每个货物进行编号,自动扫描识别货物信息,跟踪货物的状态与位置,并与后台数据库对接,可实现对货物的精准定位、智能存取、自动盘点、实时监控等。",
          // icon: require("@/assets/wash/swiper2/icon3.png"),
          // icon1: require("@/assets/wash/swiper2/icon3c.png"),
        },
        {
          image: require("@/assets/home/swiper/4.png"),
          mImage: require("@/assets/home/swiper/4m.png"),
          text: "智能试衣间",
          imgOffset: "-24%",
          info: "RFID读取设备配合后台系统，轻松统计出衣物的试用次数以及购买率，数据分析便于商家调整策略与计划。",
          // icon: require("@/assets/wash/swiper2/icon4.png"),
          // icon1: require("@/assets/wash/swiper2/icon4c.png"),
        },
        {
          image: require("@/assets/home/swiper/5.png"),
          mImage: require("@/assets/home/swiper/5m.png"),
          text: "快速查找",
          imgOffset: "-30%",
          info: "在RFID服装智慧门店使用RFID手持式设备，通过对指定服装信息的锁定，可以方便快捷的找到指定服装。",
          // icon: require("@/assets/wash/swiper2/icon5.png"),
          // icon1: require("@/assets/wash/swiper2/icon5c.png"),
        },
        {
          image: require("@/assets/home/swiper/6.png"),
          mImage: require("@/assets/home/swiper/6m.png"),
          text: "快速收银",
          imgOffset: "-57%",
          info: "生产销售清单，大大节省结账时间，使顾客有良好的购物体验，提升品牌形象。",
          // icon: require("@/assets/wash/swiper2/icon6.png"),
          // icon1: require("@/assets/wash/swiper2/icon6c.png"),
        },
        {
          image: require("@/assets/home/swiper/7.png"),
          mImage: require("@/assets/home/swiper/7m.jpg"),
          text: "防盗防损",
          imgOffset: "-84%",
          info: "当未付款的商品离开店面时，RFID服装智慧门店的RFID门禁式设备将及时的检测到该行为，并联动报警，避免企业的损失。",
          // icon: require("@/assets/wash/swiper2/icon7.png"),
          // icon1: require("@/assets/wash/swiper2/icon7c.png"),
        },
      ],
    };
  },
  components: {
    commonSwiper,
    mCommonSwiper,
  },
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
}

@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
