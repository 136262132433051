<template>
  <div class="container">
    <div class="main">
      <div class="title wow animate__fadeInLeft">供应链合作伙伴：</div>
      <div class="list" ref="list">
        <div class="rowup cc">
          <!--      :style="[`transform: translateX(${-450 * current}px)`]"-->
          <div class="list-item" v-for="(item, index) in list" :key="index">
            <img :src="item.img" />
          </div>
          <div class="list-item" v-for="(item, index) in list" :key="index">
            <img :src="item.img" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        { img: require("@/assets/home/partner/01.jpg") },
        { img: require("@/assets/home/partner/02.jpg") },
        { img: require("@/assets/home/partner/03.png") },
        { img: require("@/assets/home/partner/04.jpg") },
        { img: require("@/assets/home/partner/05.jpg") },
        { img: require("@/assets/home/partner/06.jpg") },
        // { img: require("@/assets/home/f/07.jpg") },
        { img: require("@/assets/home/partner/08.png") },
        { img: require("@/assets/home/partner/10.jpg") },
      ],
      current: 0,
      time: null,
    };
  },
  methods: {
    // addAnimate() {
    //   let { current, addAnimate, list } = this;
    //   let animation = this.$refs.list.animate(
    //     [
    //       { transform: `translateX(${-450 * current}px)` },
    //       { transform: `translateX(${-450 * (current + 1)}px)` },
    //     ],
    //     {
    //       duration: 3000,
    //       "fill-mode": "forwards",
    //       "timing-function": "linear",
    //       delay: "1000",
    //     }
    //   );
    //   // this.current++;
    //   // if(this.current === list.length)this.current=0
    //   this.current = ++this.current % list.length;
    //   animation.addEventListener(
    //     "finish",
    //     function () {
    //       addAnimate();
    //     },
    //     false
    //   );
    // },
  },
  mounted() {
    // this.addAnimate();
    // let { list } = this;
    // let _this = this;
    // this.time = setInterval(() => {
    //   if (this.current === list.length) {
    //     _this.current = 0;
    //     this.$refs.list.style.transition = "none";
    //     this.$refs.list.style.transform = `translateX(0px)`;
    //   } else {
    //     this.current++;
    //     this.$refs.list.style.transition = "all 3s linear";
    //     this.$refs.list.style.transform = `translateX(${
    //       -355 * this.current
    //     }px)`;
    //   }
    // }, 3000);
  },
  unmounted() {
    this.time && clearInterval(this.time);
  },
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  .container {
    flex-grow: 1;
    display: flex;
    align-items: center;
    background: linear-gradient(180deg, #f4f4f5 0%, #e5e6e8 100%);
    width: 100%;
  }

  .main {
    //background: linear-gradient(180deg, #f4f4f5 0%, #e5e6e8 100%);

    overflow: hidden;
    flex-grow: 1;

    @if ($searchWidth<1100) {
    } @else {
      height: 3.5rem;
    }
  }

  .title {
    font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #333333;
    letter-spacing: 0.01rem;
    margin: 0 0 1rem 1.72rem;

    @if ($searchWidth<1100) {
      font-size: 1rem;
      padding-top: 0.85rem;
    } @else {
      font-size: 0.3rem;
      padding-top: 0.5rem;
      line-height: 0.42rem;
    }
  }

  .list {
    display: flex;
    transform: translateX(0px);
    //transition: all 3s;
  }

  .list-item {
    background: #ffffff;
    box-shadow: 0 0.02rem 0.08rem 0 rgba(0, 0, 0, 0.11);
    flex-shrink: 0;
    text-align: center;
    line-height: 1.65rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @if ($searchWidth<1100) {
      width: 9.25rem;
      height: 3.25rem;
      border-radius: 0.5rem;
      margin: 0 0.3rem 1.5rem;

      img {
        // width: 6.25rem;
        height: 100%;
      }
    } @else {
      width: 3.08rem;
      height: 1.18rem;
      border-radius: 0.16rem;

      margin: 0 0.1rem 0.5rem;

      img {
        width: 1.5rem;
      }
    }
  }

  @keyframes rowup {
    0% {
      transform: translate3d(0, 0, 0);
    }

    100% {
      transform: translate3d(-19.68rem, 0, 0); //328
      display: none;
    }
  }

  //.list {
  //  width: 300px;
  //  border: 1px solid #999;
  //  margin: 20px auto;
  //  position: relative;
  //  height: 200px;
  //  overflow: hidden;
  //}

  .list .rowup {
    animation: 30s rowup linear infinite normal;
    position: relative;
    display: flex;
  }
}

@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
