<template>
  <div class="box">
    <div class="footer">
      <div class="logo-nav">
        <div class="logo"><img :src="require('@/assets/logo1.png')" /></div>
        <div class="nav">
          <div
            class="nav-item"
            v-for="(item, index) in nav"
            :key="index"
            @click="to(item.to, item.md)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="info">
        <div class="phone info-item">
          <div class="label">
            <img :src="require('@/assets/phone-icon.png')" />
          </div>
          <div class="value">
            <div>热线电话</div>
            <div class="num">0755-8891-0613</div>
          </div>
        </div>
        <div class="email info-item">
          <div class="label">邮箱：</div>
          <div class="value">info@cirteklink.com</div>
        </div>
        <div class="address info-item">
          <div class="label">地址：</div>
          <div class="value">
            深圳市龙岗区雅宝路1号星河WORLD A栋大厦13层A1303B
          </div>
        </div>
      </div>
      <div>
        <div class="qrcode"><img :src="require('@/assets/qrcode.png')" /></div>
        <div class="qrcode-txt">关注我们 官方微信</div>
      </div>
    </div>
    <div class="br"></div>
    <div class="ba">
      <img :src="require('@/assets/gongan.png')" />
      <a
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030402003880"
        target="_blank"
      >
      粤公网安备 44030402003880号
      </a>
      <div class="shu"></div>
      <a
        href="https://beian.miit.gov.cn/#/Integrated/index"
        target="_blank"
      >粤ICP备20019406号</a>
      <div class="shu you-qing-shu"></div>
      <div class="you-qing">
        友情链接：<a href="https://www.cirteklink.com/" target="_blank"
          >常达智能物联（深圳）有限公司</a
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      nav: [
        { name: "首页", to: "/home#banner", md: true },
        { name: "产品描述", to: "/home#tags", md: true },
        { name: "解决方案", to: "/home#fangAn", md: true },
        { name: "行业应用", to: "/home#apply", md: true },
        { name: "关于我们", to: "/home#about", md: true },
      ],
    };
  },
  methods: {
    to(url, md) {
      if (md) window.location = url;
      else this.$router.push(url);
    },
  },
};
</script>
<style scoped lang="scss">
@mixin searchWidth($searchWidth) {
  @if ($searchWidth<1100) {
    .box {
      padding: 0 0.8rem;
      box-sizing: border-box;
    }
  } @else {
    .box {
      width: 100%;
    }
  }

  .footer,
  .br {
    @if ($searchWidth<1100) {
      // margin: 0 0.8rem;
    } @else {
      max-width: 13.2rem;
      margin: auto;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    padding: 0.63rem 0 0.93rem;
    width: 100%;

    @if ($searchWidth<1100) {
      flex-wrap: wrap;
      // justify-content: center;

      .logo-nav {
        width: 100%;
      }

      // >div {
      //   width: 50%;
      // }
    }
  }

  .logo {
    @if ($searchWidth<1100) {
      width: 10rem;
    } @else {
      width: 4.16rem;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  .nav {
    display: flex;

    @if ($searchWidth<1100) {
      margin-top: 0.5rem;
      margin-bottom: 1.2rem;
    } @else {
      margin-top: 0.3rem;
    }

    .nav-item {
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #333333;
      letter-spacing: 0.01rem;
      cursor: pointer;

      @if ($searchWidth<1100) {
        font-size: 0.6rem;

        &:not(:first-child) {
          margin-left: 1.2rem;
        }
      } @else {
        line-height: 0.25rem;
        font-size: 0.18rem;

        &:not(:first-child) {
          margin-left: 0.3rem;
        }
      }
    }
  }

  .info {
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #333333;
    letter-spacing: 0.01rem;

    @if ($searchWidth<1100) {
      font-size: 0.45rem;
    } @else {
      font-size: 0.18rem;
    }

    .info-item {
      display: flex;
      margin-bottom: 。10rem;

      @if ($searchWidth<1100) {
        max-width: 10.6rem;
      }

      .label {
        flex-shrink: 0;

        @if ($searchWidth<1100) {
          width: 1.5rem;
        } @else {
          width: 0.61rem;
        }
      }
    }

    .info-item.phone {
      img {
        // width: 0.4rem;

        @if ($searchWidth<1100) {
          width: 1rem;
          margin-bottom: 0.5rem;
        } @else {
          width: 0.4rem;
          margin-bottom: 0.2rem;
        }
      }

      .num {
        font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
        color: #333333;

        @if ($searchWidth<1100) {
          font-size: 0.6rem;
        } @else {
          font-size: 0.24rem;
        }
      }
    }
  }

  .qrcode {
    @if ($searchWidth<1100) {
      width: 3.2rem;
      margin: auto;
    } @else {
      width: 1.45rem;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  .qrcode-txt {
    font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #333333;
    letter-spacing: 0.01rem;
    text-align: center;

    @if ($searchWidth<1100) {
      font-size: 0.45rem;
      margin-top: 0.2rem;
    } @else {
      font-size: 0.18rem;
      margin-top: 0.1rem;
    }
  }

  .br {
    height: 0.01rem;
    background: #e5e6eb;
    width: 100%;
  }

  .ba {
    display: flex;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #333333;
    letter-spacing: 0.01rem;
    justify-content: center;
    align-items: center;
    margin: 0.29rem 0 0.5rem;

    @if ($searchWidth<1100) {
      font-size: 0.45rem;
      flex-wrap: wrap;
      .you-qing {
        width: 100%;
        text-align: center;
      }
      .you-qing-shu {
        display: none;
      }
    } @else {
      font-size: 0.18rem;
    }
    a {
      text-decoration: none;
      color: #333333;
    }
    img {
      @if ($searchWidth<1100) {
        width: 0.55rem;
        margin-right: 0.25rem;
      } @else {
        width: 0.18rem;
        margin-right: 0.11rem;
      }
    }

    .shu {
      width: 0.01rem;
      height: 0.25rem;
      background: #e5e6eb;
      margin: 0 0.09rem;
    }
  }
}

@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
