<template>
  <div class="container home-container">
    <div class="f1">RFID标签时代</div>
    <div class="f2">
      RFID产品结合智能门店系统让产品不可复制，让供应链数字化、可视化。服装门店管理系统提高了服装管理水平，降低了管理成本，增强了核心竞争力，逐渐成为品牌服装厂不可缺少的技术工具和方法。
    </div>
    <div class="nav-box">
      <div class="nav">
        <div
          class="nav-item"
          :class="{ current: current === index }"
          v-for="(item, index) in navs"
          :key="index"
          @click="current = index"
        >
          {{ item.name }}
        </div>
        <div
          class="nav-current"
          :style="`transform:translateX(${100 * current}%)`"
        >
          <div></div>
        </div>
      </div>
    </div>
    <card
      :data="navs[current].children"
      :to="navs[current].to"
      :toName="navs[current].toName"
    ></card>
  </div>
</template>
<script>
import card from "./card";
export default {
  components: {
    card,
  },
  data() {
    return {
      current: 0,
    };
  },
  setup() {
    let navs = [
      {
        name: "RFID产品",
        children: [
          {
            navName: "RFID/二维码标签",
            mNavName: "RIFD标签",
            name: "RFID/二维码标签",
            info: "使用二维码技术让标签栩栩如生，创造新的数字营销机会来吸引客户并加强品牌忠诚度。",
            merit: [
              "缩小的标签尺寸提供成本效益并支持可持续性",
              "在不影响交货时间或成本的情况下支持最后一分钟的内容更改",
              "不需要高技术能力来实施",
            ],
            image: require("@/assets/home/tags/1-1.png"),
            mImage: require("@/assets/home/tags/1-1m.png"),
            icon: require("@/assets/home/tags/icon1.png"),
          },

          {
            navName: "价格吊牌标签",
            mNavName: "价格吊牌",
            name: "价格吊牌标签",
            info: "吊牌及价格牌展示了大量产品或服裝信息，针对采用RFID的品牌，我们的吊牌及价格牌可以汇入RFID嵌体，亦可制为贴纸附于吊牌表面。",
            merit: [
              "采用各种可持续材料及形式",
              "确保各地品质及颜色始終如一",
              "支持RFID及二维码技术",
            ],
            image: require("@/assets/home/tags/1-2.png"),
            mImage: require("@/assets/home/tags/1-2m.png"),
            icon: require("@/assets/home/tags/icon2.png"),
          },
          {
            navName: "织唛标签",
            mNavName: "织唛标签",
            name: "织唛标签",
            info: "无论是单纯用于品牌推广，还是包含更详细的产品信息，织唛标签都能更好展现品牌形象。",
            merit: [
              "使用最优质的纱线和材料",
              "织唛生产工艺中包含多种织边和整理技术",
              "专家团队选择契合品牌形象与产品类型的款式",
            ],
            image: require("@/assets/home/tags/1-3.png"),
            mImage: require("@/assets/home/tags/1-3m.png"),
            icon: require("@/assets/home/tags/icon3.png"),
          },
          {
            navName: "标签贴纸",
            mNavName: "标签贴纸",
            name: "标签贴纸",
            info: "采用定制化，大陆一般用定制化的贴纸满足您所有产品及包装需要，让贴纸标签的应用更轻松简单。",
            merit: [
              "定制化形状及大小",
              "永久性或可移除不干胶",
              "适用于各种材质表面",
            ],
            image: require("@/assets/home/tags/1-4.png"),
            mImage: require("@/assets/home/tags/1-4m.png"),
            icon: require("@/assets/home/tags/icon4.png"),
          },
        ],
        to: "/tags",
        toName: "产品详情",
      },
      {
        name: "智能门店系统",
        children: {
          name: "智能门店系统",
          info: "常达智能物联运用RFID技术引入商品管理，结合无线网络通讯技术的应用，实现销售现场商品信息的实时查询，以及销售数据的上传、智能化统计与管理，方便快捷，提升工作效率，降低运营成本的同时，显著提高商场档次，提升消费者购物体验。",
          merit: [
            "以数据为驱动，挖掘企业自身数据价值，实现品牌的全数字化经营，驱动品牌智能发展。",
            "门店数据全面解析，智能指导门店经营，提高门店坪效。",
            "服务统一，体验升级，让品牌客户更加便捷、舒适地购物，打造品牌忠诚度。",
          ],
          image: require("@/assets/home/tags/2.png"),
          mImage: require("@/assets/home/tags/2m.png"),
          icon: require("@/assets/home/tags/icon5.png"),
        },
        to: "/store",
        toName: "系统详情",
      },
    ];
    return { navs };
  },
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  .container {
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 15px;
    //background: red;

    @if ($searchWidth<1100) {
      padding-bottom: 2rem;
      background-image: url("~@/assets/home/tags/m-bg.png");
    } @else {
      background-image: url("~@/assets/home/tags/bg.png");
    }
  }

  @if ($searchWidth<1100) {
    .nav-box {
      padding: 0 1.6rem;
      width: 100%;
      box-sizing: border-box;
    }
  }

  .nav {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0.2rem;
    background: linear-gradient(180deg, #fcffff 0%, #ffffff 100%);
    box-shadow: 0 0.02rem 0.11rem 0 rgba(83, 177, 165, 0.23);
    border: 0.02rem solid #ffffff;
    box-sizing: border-box;
    position: relative;
    margin: 0 auto;

    @if ($searchWidth<1100) {
      // width: 9.2rem;
      height: 2.4rem;
      border-radius: 1.2rem;
      width: 100%;
      padding: 0.4rem;
    } @else {
      padding: 0.06rem 0.1rem;
      width: 4.6rem;
      height: 0.62rem;
      border-radius: 0.31rem;
    }

    .nav-item {
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #333333;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.21rem;
      z-index: 10;
      transition: all 0.3s;
      text-align: center;
      cursor: pointer;

      @if ($searchWidth<1100) {
        font-size: 0.6rem;
      } @else {
        font-size: 0.24rem;
      }

      &.current {
        color: #fff;
      }
    }

    .nav-current {
      z-index: 9;
      width: 50%;
      border-radius: 0.21rem;
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
      transition: all 0.3s;
      box-sizing: border-box;

      @if ($searchWidth<1100) {
        padding: 0.4rem;
      } @else {
        padding: 0.06rem 0.1rem;
      }

      > div {
        width: 100%;
        height: 100%;
        background: #5ec7b9;
        border-radius: 999999px;
      }
    }
  }
}

@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
