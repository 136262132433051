<template>
  <div class="main">
    <div
      class="item"
      v-for="(item, index) in list"
      :key="index"
      @click="to(`${item.to}#${item.name}`)"
    >
      <div class="icon"><img :src="item.icon" /></div>
      <div class="name">{{ item.name }}</div>
      <div class="info">{{ item.info }}</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        {
          icon: require("@/assets/header/about/icon1.png"),
          name: "全球业务",
          info: "全球40多个国家的办事处以及7国生产线构建完整全球供应链",
          to: "/about",
        },
        {
          icon: require("@/assets/header/about/icon2.png"),
          name: "公司简介",
          info: "专注于RFID核心技术，拥有自主研发团队，开发RFID系统平台、RFID产品",
          to: "/about",
        },
        {
          icon: require("@/assets/header/about/icon3.png"),
          name: "生产实力",
          info: "常达控股在中国、孟加拉、土耳其、印度及越南均设有工厂",
          to: "/about",
        },
        {
          icon: require("@/assets/header/about/icon4.png"),
          name: "联系我们",
          info: "提供专业的售前服务、实施服务、售后服务，欢迎与我们联系",
          to: "/about",
        },
      ],
    };
  },
  methods: {
    to(url) {
      window.location = url;
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  max-width: 12.5rem;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 0.47rem 0;
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      .name,
      .info {
        color: #5ec7b9;
      }
    }
  }
  .name {
    font-size: 0.2rem;
    font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #333333;
    margin: 0.15rem 0;
  }
  .info {
    font-size: 0.16rem;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #7a7a7a;
    max-width: 2.7rem;
    text-align: center;
  }
  .icon {
    width: 0.6rem;
    img {
      width: 100%;
      display: block;
    }
  }
}
</style>
