import { createStore } from "vuex";

export default createStore({
  state: {
    navs: [
      {
        name: "首页",
        to: "/home#banner",
      },
      {
        name: "产品与技术",
        childWidth: "1.5rem",
        to: "/home#tags",
        child: [
          {
            name: "产品描述",
            to: "/tags",
            c: "tags",
          },
          {
            name: "智能门店系统",
            to: "/store",
            c: "store",
          },
        ],
      },
      {
        name: "解决方案",
        childWidth: "1.64rem",
        to: "/home#fangAn",
        // md: true,
        // 9
      },
      {
        name: "行业应用",
        childWidth: "2rem",
        to: "/home#apply",
      },
      {
        name: "新闻中心",
        childWidth: "2rem",
        to: "/tidings",
      },
      {
        name: "关于我们",
        childWidth: "1.54rem",
        to: "/home#about",
        // md: true,
        child: [
          // {
          //   name: "联系我们",
          //   to: "/connect",
          // },
          {
            name: "关于公司",
            to: "/about",
            c: "about",
          },
        ],
      },
      {
        name: "更多解决方案",
        to: "https://www.cirteklink.com/home",
        md: true,
        // md: true,
        // 9
      },
    ],
    headerColor: "black",
    headerTopBtnWhite: false,
  },
  mutations: {
    setHeaderColorBlack(state) {
      state.headerColor = "black";
    },
    setHeaderColorWhite(state) {
      state.headerColor = "white";
    },
    setheaderTopBtnWhite(state, d) {
      state.headerTopBtnWhite = d;
    },
    scrollToSection(state, id) {
      if (!id) return;
      id = id.slice(1);
      let section = document.getElementById(id);
      if (section) {
        section.scrollIntoView();
      }
    },
  },
  actions: {},
  getters: {
    navs: (state) => state.navs,
    headerColor: (state) => state.headerColor,
    headerTopBtnWhite: (state) => state.headerTopBtnWhite,
  },
  modules: {},
});
