<template>
  <div class="mobile-nav" :style="!visible && 'transform:translateX(100%);'">
    <div class="main">
      <!-- <div class="close" @click="$emit('update:visible', false)">
        <img :src="require('@/assets/close.png')" />
      </div> -->
      <div
        class="list"
        @touchend.stop=""
        @touchstart.stop=""
        @touchmove.stop=""
      >
        <div class="list-item" v-for="(item, index) in list" :key="index">
          <div
            :class="{ to: item.to, current: index === current }"
            class="nav"
            @click="navBtn(item, index)"
          >
            {{ item.name }}
          </div>
          <!-- <template v-if="item.child">
            <div
              :class="childItem.to && 'to'"
              class="child-nav"
              v-for="(childItem, childIndex) in item.child"
              :key="childIndex"
            >
              <div @click="childItem.to && $router.push(childItem.to)">
                {{ childItem.name }}
              </div>
              <template v-if="childItem.children">
                <div
                  :class="child2Item.to && 'to'"
                  class="child2-nav child-nav"
                  @click="child2Item.to && $router.push(child2Item.to)"
                  v-for="(child2Item, child2Index) in childItem.children"
                  :key="child2Index"
                >
                  <div>{{ child2Item.name }}</div>
                </div>
              </template>
            </div>
          </template> -->
        </div>
      </div>

      <div class="child-list" v-if="current && list[current].child">
        <div v-for="(item, index) in list[current].child" :key="index">
          <div
            class="child-t"
            v-if="child[item.c].name"
            @click="to(item.to, true)"
          >
            {{ child[item.c].name }}
          </div>
          <div class="child-s">
            <div
              v-for="(sItem, sIndex) in child[item.c].s"
              :key="sIndex"
              @click="to(sItem.to, true)"
            >
              {{ sItem.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    visible: Boolean,
    list: Array,
  },
  data() {
    return {
      child: {
        store: {
          name: "智能门店系统",
          to: "/store",
          s: [
            {
              name: "智能仓储系统",
              to: "/store#智慧仓储系统",
            },
            {
              name: "智慧门店系统",
              to: "/store#智慧门店系统",
            },
            {
              name: "防窜防伪系统",
              to: "/store#防窜防伪系统",
            },
            {
              name: "智慧试衣设备",
              to: "/store#智慧试衣设备",
            },
            {
              name: "系统数字化建设",
              to: "/store#系统数字化建设",
            },
          ],
        },
        tags: {
          name: "RFID产品描述",
          to: "/tags",
          s: [
            {
              name: "RFID/二维码标签",
              to: "/tags#RFID/二维码标签",
            },
            {
              name: "价格吊牌标签",
              to: "/tags#价格吊牌标签",
            },
            {
              name: "织唛标签",
              to: "/tags#织唛标签",
            },
            {
              name: "标签贴纸",
              to: "/tags#标签贴纸",
            },
            {
              name: "电子商务包装盒",
              to: "/tags#电子商务包装盒",
            },
            {
              name: "热转印",
              to: "/tags#热转印",
            },
            {
              name: "辅料",
              to: "/tags#辅料",
            },
          ],
        },
        about: {
          s: [
            {
              name: "全球业务",
              to: "/about#全球业务",
            },
            {
              name: "公司简介",
              to: "/about#公司简介",
            },
            {
              name: "生产实力",
              to: "/about#生产实力",
            },
            {
              name: "联系我们",
              to: "/about#联系我们",
            },
          ],
        },
      },
      current: null,
    };
  },
  inject: ["to"],
  watch: {
    visible: {
      immediate: true,
      handler() {
        this.current = null;
      },
    },
    $route() {
      this.$emit("update:visible", false);
    },
  },
  methods: {
    navBtn(item, index) {
      if (item.child) {
        this.current = index;
      } else {
        item.to && this.to(item.to, true);
        this.$emit("update:visible", false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.mobile-nav {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 998;
  transition: all 0.2s;
  display: flex;
  justify-content: flex-end;

  // background: rgba(0, 0, 0, 0.3);
  .main {
    width: 100%;
    height: calc(100% - 3.2rem);
    overflow: auto;
    display: flex;
    // flex-direction: column;
    background: #fafafa;
    margin-top: 3.2rem;
  }

  // .close {
  //   display: flex;
  //   justify-content: flex-end;
  //   align-items: center;
  //   height: 3.2rem;
  //   padding-right: 1rem;
  //   box-sizing: border-box;
  //   flex-shrink: 0;
  //   box-shadow: 0px 15px 10px -15px #eee;
  //   img {
  //     width: 1rem;
  //   }
  // }
  .list {
    // flex-grow: 1;
    height: 100%;
    overflow: auto;
    // padding-bottom: 1rem;
  }

  .list-item {
    font-size: 0.5rem;
    // padding-left: 1rem;
    box-sizing: border-box;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;

    .to {
      cursor: pointer;
    }

    .nav {
      // margin: 0.5rem 0 0.5rem;
      // border-bottom: 1px solid #e5e6e8;
      font-size: 0.7rem;
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      // line-height: 1.5;
      letter-spacing: 0.02rem;
      // text-shadow: 0.01rem 0.03rem 0.03rem rgba(0, 0, 0, 0.7);
      color: #333333;
      height: 2.5rem;
      line-height: 2.5rem;
      width: 6.5rem;
      // background: red;
      text-indent: 1.5rem;

      &.current {
        background: #f3f3f3;
      }
    }

    // .child-nav {
    //   font-size: 0.65rem;
    //   padding-left: 0.1rem;
    //   box-sizing: border-box;
    //   font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    //   font-weight: normal;
    //   color: #7a7a7a;
    //   line-height: 2rem;
    //   text-indent: 1.7rem;
    //   letter-spacing: 0.02rem;
    // }

    // .child2-nav {
    //   padding-left: 1rem;
    //   position: relative;

    //   &:after {
    //     content: "";
    //     position: absolute;
    //     top: 50%;
    //     transform: translateY(-50%);
    //     left: 0.8rem;
    //     background: #7a7a7a;
    //     width: 0.2rem;
    //     height: 0.2rem;
    //     border-radius: 50%;
    //   }
    // }
  }

  .child-list {
    flex-grow: 1;
    background: #f3f3f3;
    padding: 0.3rem 0.75rem 0;
    height: 100%;
    overflow: auto;

    .child-t {
      font-size: 0.7rem;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #333333;
      line-height: 1rem;
      position: relative;
      text-indent: 0.8rem;
      margin-top: 0.75rem;

      &::before {
        content: "";
        position: absolute;
        width: 0.15rem;
        height: 0.65rem;
        background: linear-gradient(31deg, #97e5dd 0%, #5ec7b9 100%);
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }

    .child-s {
      font-size: 0.7rem;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #333333;
      line-height: 1rem;

      > div {
        padding: 0.75rem 0;
        border-bottom: 0.05rem solid #dcdcdc;
      }
    }
  }
}
</style>
