<template>
  <div class="moblie">
    <div id="banner"></div>
    <top></top>
    <div id="tags"></div>
    <tags></tags>
    <div id="fangAn"></div>
    <program></program>
    <div id="apply"></div>
    <apply></apply>
    <news></news>
    <div id="about"></div>
    <about></about>
    <partner></partner>
    <CommonFooter></CommonFooter>
  </div>
  <!--  <div class="home">-->
  <swiper
    class="home pc"
    ref="swiper"
    :slides-per-view="1"
    :direction="'vertical'"
    :mousewheel="true"
    @Swiper="setSwiperInstance($event)"
    @slideChangeTransitionEnd="slideChangeTransitionEnd"
  >
    <swiper-slide>
      <top></top>
    </swiper-slide>
    <swiper-slide>
      <tags></tags>
    </swiper-slide>
    <swiper-slide>
      <program />
    </swiper-slide>
    <swiper-slide>
      <apply id="apply1"></apply>
    </swiper-slide>
    <swiper-slide>
      <news></news>
    </swiper-slide>
    <swiper-slide>
      <div id="about1"></div>
      <about></about>
    </swiper-slide>
    <swiper-slide>
      <div class="home-container">
        <partner></partner>
        <common-footer></common-footer>
      </div>
    </swiper-slide>
  </swiper>
  <!--  </div>-->
</template>

<script>
import top from "./top";
import tags from "./tags";
import program from "./program";
import CommonFooter from "@components/footer.vue";
import apply from "./apply";
import news from "./news";
import about from "./about";
import partner from "./partner";
import { mapMutations } from "vuex";
// eslint-disable-next-line no-unused-vars
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { Swiper as swiper, Mousewheel } from "swiper";
swiper.use(Mousewheel);
import "swiper/swiper-bundle.min.css";
export default {
  components: {
    top,
    tags,
    program,
    CommonFooter,
    apply,
    news,
    about,
    partner,
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      mySwiper: null,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.init();
      },
    },
  },
  methods: {
    ...mapMutations([
      "setHeaderColorBlack",
      "setHeaderColorWhite",
      "setheaderTopBtnWhite",
    ]),
    setSwiperInstance(s) {
      this.mySwiper = s;
    },
    init() {
      // let mySwiper = this.mySwiper;
      let { mySwiper, $route } = this;
      // let h = document.querySelector(".header-container-box.pc");
      if (
        ["#tags", "#fangAn", "#apply", "#about", "#news", "#other"].includes(
          $route.hash
        )
      ) {
        this.setheaderTopBtnWhite(false);
        // h.classList.remove("none-bg");
      } else {
        this.setheaderTopBtnWhite(true);
        // h.classList.add("none-bg");
      }
      switch ($route.hash) {
        case "#banner":
          mySwiper && mySwiper.slideTo(0);
          break;
        case "#tags":
          mySwiper && mySwiper.slideTo(1);
          break;
        case "#fangAn":
          mySwiper && mySwiper.slideTo(2);
          break;
        case "#apply":
          mySwiper && mySwiper.slideTo(3);
          break;
        case "#news":
          mySwiper && mySwiper.slideTo(4);
          break;
        case "#about":
          mySwiper && mySwiper.slideTo(5);
          break;
      }
    },
    slideChangeTransitionEnd(s) {
      let hash = ["banner", "tags", "fangAn", "apply", "news", "about"];
      this.$router.push(`/home#${hash[s.activeIndex] || "other"}`);
    },
  },
  mounted() {
    this.setHeaderColorWhite();
    this.setheaderTopBtnWhite(true);
    this.init();
  },
  unmounted() {
    this.setHeaderColorBlack();
    this.setheaderTopBtnWhite(false);
  },
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  @if ($searchWidth<1100) {
    .pc {
      display: none;
    }
  } @else {
    .moblie {
      display: none;
    }
  }

  .home {
    width: 100%;
    height: 100vh;
  }
}

@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
