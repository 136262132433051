import { createRouter, createWebHistory } from "vue-router";
//createWebHashHistory
import Home from "../views/home";

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/tags",
    name: "Tags",
    component: () => import("@/views/tags/index"),
  },
  {
    path: "/tags1",
    name: "Tags1",
    component: () => import("@/views/tags1/index"),
  },
  {
    path: "/store",
    name: "Store",
    component: () => import("@/views/store/index"),
  },
  {
    path: "/tidings",
    // component: About,
    component: () => import("@/views/news/index"),
    name: "News",
  },
  {
    path: "/About",
    name: "About",
    component: () => import("@/views/about/index"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
// function setMeta(name, value) {
//   let dom = document.querySelector(`meta[name="${name}"]`);
//   dom && dom.setAttribute("content", value);
// }
// router.beforeEach((to) => {
//   let { title, keywords, description } = to.meta;
//   if (title) document.title = title;
//   if (keywords) {
//     setMeta("keywords", keywords);
//   }
//   if (description) {
//     setMeta("description", description);
//   }
//   if (title) {
//     setMeta("title", title);
//   }
// });
export default router;
