<template>
  <div class="main">
    <div v-for="(item, index) in d" :key="index">
      <div
        class="title"
        :style="`background-image: url(${item.bg})`"
        @click="to(`${item.to}`)"
      >
        {{ item.title }}
      </div>
      <div class="br"></div>
      <div class="child">
        <div>
          <div
            v-for="(childItem, childIndex) in item.child"
            :key="childIndex"
            @click="to(`${childItem.to}#${childItem.name}`)"
          >
            <div class="name">
              <img :src="childItem.icon" />{{ childItem.name }}
            </div>
            <div class="info">{{ childItem.info }}</div>
          </div>
        </div>
        <div>
          <div
            v-for="(childItem, childIndex) in item.child2"
            :key="childIndex"
            @click="to(`${childItem.to}#${childItem.name}`)"
          >
            <div class="name">
              <img :src="childItem.icon" />{{ childItem.name }}
            </div>
            <div class="info">{{ childItem.info }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      d: [
        {
          title: "RFID产品描述",
          bg: require("@/assets/header/title-bg1.png"),
          to: "/tags",
          child: [
            {
              icon: require("@/assets/header/tags/icon1.png"),
              name: "RFID/二维码标签",
              info: "使用二维码技术让标签栩栩如生，加强品牌忠诚度",
              to: "/tags",
            },
            {
              icon: require("@/assets/header/tags/icon2.png"),
              name: "价格吊牌标签",
              info: "我们的吊牌及价格牌可以汇入RFID嵌体，可贴于吊牌表面",
              to: "/tags",
            },
            {
              icon: require("@/assets/header/tags/icon3.png"),
              name: "织唛标签",
              info: "用于品牌推广，织唛标签都能更好展现品牌形象",
              to: "/tags",
            },
            {
              icon: require("@/assets/header/tags/icon4.png"),
              name: "标签贴纸",
              info: "采用定制化的贴纸满足您所有产品及包装需要",
              to: "/tags",
            },
          ],
          child2: [
            {
              icon: require("@/assets/header/tags/icon5.png"),
              name: "电子智能包装盒",
              info: "环保包装到电子商务包装和定制零售包装，满足您的需求",
              to: "/tags",
            },
            {
              icon: require("@/assets/header/tags/icon6.png"),
              name: "热转印",
              info: "随着运动服和贴身内衣的兴起，客户现在正在积极寻找无标签品牌",
              to: "/tags",
            },
            {
              icon: require("@/assets/header/tags/icon7.png"),
              name: "辅料",
              info: "我们的辅料有多种类别多种材料可供选择",
              to: "/tags",
            },
          ],
        },
        {
          title: "智能门店系统",
          to: "/store",
          bg: require("@/assets/header/title-bg2.png"),
          child: [
            {
              icon: require("@/assets/header/tags/icon11.png"),
              name: "智慧仓储系统",
              info: "服装RFID仓储管理系统促进公司管理模式",
              to: "/store",
            },
            {
              icon: require("@/assets/header/tags/icon22.png"),
              name: "智慧门店系统",
              info: "为客户提供实时防损、客流统计、行为识别、数据分析管理系统",
              to: "/store",
            },
            {
              icon: require("@/assets/header/tags/icon33.png"),
              name: "防窜防伪系统",
              info: "防窜防伪系统可实现单品/批量智能识别、实时防伪查询与验证",
              to: "/store",
            },
            {
              icon: require("@/assets/header/tags/icon44.png"),
              name: "系统数字化建设",
              info: "让零售运营更高效，让门店经营更智慧",
              to: "/store",
            },
          ],
          child2: [
            {
              icon: require("@/assets/header/tags/icon55.png"),
              name: "智慧试衣设备",
              info: "分析出受客户喜欢的样式、推送试衣搭配信息、分析试衣和销售比",
              to: "/store",
            },
          ],
        },
      ],
    };
  },
  methods: {
    to(url) {
      window.location = url;
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  display: grid;
  grid-template-columns: repeat(2, 5.4rem);
  grid-column-gap: 1.31rem;
  justify-content: center;
  font-size: 16px;
  padding-bottom: 0.35rem;
  padding-top: 0.27rem;
  .title {
    font-size: 0.2rem;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #333333;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0.16rem 0 0.16rem 0.3rem;
    position: relative;
    cursor: pointer;
    &:after {
      content: "";
      position: absolute;
      left: 0.2rem;
      top: 50%;
      transform: translateY(-50%);
      width: 0.03rem;
      height: 0.18rem;
      background: linear-gradient(31deg, #97e5dd 0%, #5ec7b9 100%);
    }
  }
  .br {
    width: 100%;
    height: 0.02rem;
    opacity: 0.45;
    background: #c0c0c0;
    margin-top: 0.23rem;
  }
  .child {
    display: flex;
    justify-content: space-between;
    > div {
      max-width: 2.4rem;
      > div:hover {
        .name {
          color: #5ec7b9;
          cursor: pointer;
        }
      }
    }
    .name {
      font-size: 0.2rem;
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #333333;
      display: flex;
      align-items: center;
      margin-top: 0.23rem;
    }
    img {
      width: 0.25rem;
      display: block;
      margin-right: 0.15rem;
    }
    .info {
      font-size: 0.16rem;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #7a7a7a;
      line-height: 0.25rem;
      margin-top: 0.09rem;
    }
  }
}
</style>
