<template>
  <div class="card">
    <div class="main">
      <div class="nav" v-if="isArray">
        <div
          class="nav-item"
          :class="{ current: current === index }"
          v-for="(item, index) in data"
          :key="index"
          @click="current = index"
        >
          {{ item.navName }}
        </div>
      </div>
      <div class="m-nav" v-if="isArray">
        <div
          class="nav-item"
          :class="{ current: current === index }"
          v-for="(item, index) in data"
          :key="index"
          @click="current = index"
        >
          {{ item.mNavName }}
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          <img :src="currentData.icon" />{{ currentData.name }}
        </div>
        <div class="content-info">{{ currentData.info }}</div>
        <div class="content-merit">
          <div v-for="(item, index) in currentData.merit" :key="index">
            <img :src="require('@/assets/gou.png')" />
            <div class="merit-t">{{ item }}</div>
          </div>
        </div>
      </div>
      <div class="image">
        <img class="image1" :src="currentData.image" :alt="currentData.name" />
        <img class="image2" :src="currentData.mImage" :alt="currentData.name" />
      </div>
    </div>
    <div class="more" @click="$router.push(to)">{{ toName }}</div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: [Array, Object],
      default: () => {},
    },
    to: String,
    toName: String,
  },
  data() {
    return {
      current: 0,
    };
  },
  watch: {
    data: {
      immediate: true,
      handler() {
        this.current = 0;
      },
    },
  },
  computed: {
    currentData() {
      return this.isArray ? this.data[this.current] : this.data;
    },
    isArray() {
      return Array.isArray(this.data);
    },
  },
};
</script>
<style scoped lang="scss">
@mixin searchWidth($searchWidth) {
  .card {
    box-shadow: 0 0.04rem 0.11rem 0.03rem rgba(83, 177, 165, 0.23);
    border: 0.02rem solid #ffffff;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;

    @if ($searchWidth<1100) {
      min-height: 4.5rem;
      margin: 0.85rem 0.8rem 0;
      padding: 0 0 0.8rem 0;
      border-radius: 0.5rem;
      overflow: hidden;
      background: linear-gradient(180deg, #fcffff 0%, #ffffff 100%);
    } @else {
      height: 4.5rem;
      //min-height: 4.5rem;
      width: 13.2rem;
      margin: 0.3rem auto 0;
      padding: 0.4rem 0.4rem 0.3rem 0;
      background: linear-gradient(180deg, #fcffff 0%, #ffffff 100%);
    }
  }

  .main {
    display: flex;
    align-items: flex-start;

    @if ($searchWidth<1100) {
      flex-direction: column;

      .nav {
        display: none;
      }
    } @else {
      .m-nav {
        display: none;
      }
    }
  }

  .m-nav {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    height: 2.25rem;

    .nav-item {
      font-size: 0.7rem;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #333333;
      text-align: center;
      height: 2.25rem;
      display: flex;
      align-items: center;
      justify-content: center;

      &.current {
        background: #5ec7b9;
        color: #fff;
      }
    }
  }

  .nav {
    width: 2.38rem;
    flex-shrink: 0;
    box-sizing: border-box;
    border-right: 0.03rem solid #e5e6eb;
    cursor: pointer;

    .nav-item {
      font-size: 0.24rem;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #333333;
      padding: 0.13rem 0 0.13rem 0.36rem;
      box-sizing: border-box;

      &.current {
        border-left: 0.04rem solid #5ec7b9;
        padding: 0.13rem 0 0.13rem 0.32rem;
        color: #5ec7b9;
      }

      &:not(:first-child) {
        margin-top: 0.1rem;
      }
    }
  }

  .content {
    margin: 0 0.4rem;
    .content-title {
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #333333;
      display: flex;
      align-items: center;

      @if ($searchWidth<1100) {
        font-size: 1rem;
        margin-top: 1.1rem;

        img {
          width: 1rem;
          margin-right: 0.5rem;
        }
      } @else {
        font-size: 0.3rem;

        img {
          width: 0.4rem;
          margin-right: 0.2rem;
        }
      }
    }

    .content-info {
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #333333;

      @if ($searchWidth<1100) {
        font-size: 0.7rem;
        line-height: 0.9rem;
        margin: 0.35rem 0 0.5rem;
      } @else {
        font-size: 0.16rem;
        line-height: 0.25rem;
        margin: 0.19rem 0 0.2rem;
      }
    }

    .content-merit {
      font-size: 0.16rem;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #7a7a7a;

      @if ($searchWidth<1100) {
        font-size: 0.7rem;
        line-height: 1rem;

        img {
          width: 0.75rem;
          margin-right: 0.5rem;
          margin-top: 0.125rem;
        }
      } @else {
        font-size: 0.16rem;
        line-height: 0.25rem;

        img {
          width: 0.2rem;
          margin-right: 0.2rem;
        }
      }

      > div {
        display: flex;
        align-items: flex-start;

        @if ($searchWidth<1100) {
          margin-bottom: 0.25rem;
        } @else {
          margin-bottom: 0.1rem;
        }
      }

      // img {
      //   width: 0.2rem;
      //   margin-right: 0.2rem;
      // }
    }
  }

  .image {
    flex-shrink: 0;

    @if ($searchWidth<1100) {
      // width: 100%;
      margin: 0.5rem 0.8rem 0;

      .image1 {
        display: none;
      }
    } @else {
      width: 5.6rem;

      .image2 {
        display: none;
      }
    }

    img {
      width: 100%;
      display: block;
    }
  }

  .more {
    background: #5ec7b9;
    text-align: center;
    display: inline-block;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #ffffff;
    cursor: pointer;

    @if ($searchWidth<1100) {
      height: 1.5rem;
      line-height: 1.5rem;
      border-radius: 0.75rem;
      font-size: 0.7rem;
      padding: 0 1.65rem;
      margin-top: 0.9rem;
    } @else {
      height: 0.6rem;
      line-height: 0.6rem;
      border-radius: 0.3rem;
      font-size: 0.24rem;
      padding: 0 0.7rem;
      margin-top: 0.4rem;
    }
  }
}

@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
