<template>
  <div class="home-container">
    <div class="moblie" style="height: 2.1rem"></div>
    <div class="f1">关于我们</div>
    <div class="main">
      <div class="content">
        <div class="t">全球业务 + 制造</div>
        <div class="info">
          <p>中国•香港•孟加拉国•柬埔寨•越南•印度•斯里兰卡•</p>
          <p>巴基斯坦•比利时•荷兰•德国•意大利•土耳其•西班牙•</p>
          <p>法国•英国•危地马拉•墨西哥•纽约•洛杉矶•旧金山•波特兰</p>
        </div>
        <div class="btn" @click="$router.push('/about')">查看详情</div>
      </div>
      <div class="image">
        <img :src="require('@/assets/home/about/1.png')" />
      </div>
    </div>

    <div class="moblie" style="height: 3rem"></div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  .main {
    display: flex;
    width: 100%;
    justify-content: space-between;

    margin: 0 auto;

    @if ($searchWidth<1100) {
      padding: 0 0.8rem;
      box-sizing: border-box;
      flex-direction: column;
    } @else {
      max-width: 13.2rem;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @if ($searchWidth<1100) {
        // width: 40%;
        margin: 1rem auto 1.2rem;
      } @else {
        max-width: 3.9rem;
        margin: 0.64rem auto 1.2rem;
      }
    }

    .t {
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #5ec7b9;
      letter-spacing: 0.01rem;

      @if ($searchWidth<1100) {
        font-size: 1rem;
        text-align: center;
        margin: auto;
      } @else {
        font-size: 0.38rem;
      }
    }

    .info {
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #7a7a7a;
      margin: 0.5rem 0 1rem;
      p {
        white-space: nowrap;
      }
      //word-break: normal;
      @if ($searchWidth<1100) {
        line-height: 0.9rem;
        font-size: 0.7rem;
        text-align: center;
      } @else {
        line-height: 0.35rem;
        font-size: 0.18rem;
      }
    }

    .btn {
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #ffffff;
      background: #5ec7b9;
      cursor: pointer;

      @if ($searchWidth<1100) {
        font-size: 0.7rem;
        height: 1.5rem;
        line-height: 1.5rem;
        border-radius: 0.75rem;
        padding: 0 1.65rem;
        margin: auto;
      } @else {
        font-size: 0.24rem;
        height: 0.5rem;
        line-height: 0.5rem;
        border-radius: 0.25rem;
        padding: 0 0.52rem;
      }
    }

    .image {
      flex-shrink: 0;
      display: flex;
      align-items: center;

      @if ($searchWidth<1100) {
        width: 100%;
      } @else {
        width: 6.9rem;
      }

      img {
        width: 100%;
        display: block;
      }
    }
  }
}

@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
