<template>
  <div class="container home-container">
    <div class="moblie" style="height: 2.1rem"></div>
    <div class="f1">行业应用</div>
    <div class="box pc">
      <div class="item" v-for="(item, index) in list" :key="index">
        <div class="image"><img :src="item.image" :alt="item.name" /></div>
        <div class="logo"><img :src="item.logo" :alt="item.name" /></div>
        <div class="name">{{ item.name }}</div>
        <div class="info">{{ item.info }}</div>
      </div>
    </div>
    <div class="main moblie">
      <Swiper
        class="box"
        :style="`transform:translateX(-${current * 100}%)`"
        @Swiper="setSwiperInstance($event)"
        :slides-per-view="1"
      >
        <!-- <div class="item" v-for="(item, index) in list" :key="index">
          <div class="image"><img :src="item.image" /></div>
          <div class="logo"><img :src="item.logo" /></div>
          <div class="name">{{ item.name }}</div>
          <div class="info">{{ item.info }}</div>
        </div> -->
        <SwiperSlide
          style="height: auto; box-sizing: border-box"
          v-for="(item, index) in list"
          :key="index"
        >
          <div class="item">
            <div class="image"><img :src="item.image" :alt="item.name" /></div>
            <div class="logo"><img :src="item.logo" :alt="item.name" /></div>
            <div class="name">{{ item.name }}</div>
            <div class="info">{{ item.info }}</div>
          </div>
        </SwiperSlide>
      </Swiper>
      <div class="l" @click="toLeft">
        <img :src="require('@/assets/home/apply/left.png')" />
      </div>
      <div class="r" @click="toRight">
        <img :src="require('@/assets/home/apply/right.png')" />
      </div>
    </div>
    <!-- <Swiper style="width:100%; " slides-per-view="1.1">
      <SwiperSlide style="height:auto; border:1px solid red; box-sizing:border-box;" v-for="(item, index) in list"
        :key="index">
        <div class="item">
          <div class="image"><img :src="item.image" /></div>
          <div class="logo"><img :src="item.logo" /></div>
          <div class="name">{{ item.name }}</div>
          <div class="info">{{ item.info }}</div>
        </div>
      </SwiperSlide> -->

    <!-- <SwiperSlide style="height:1rem; border:1px solid red; box-sizing:border-box;">1</SwiperSlide>
      <SwiperSlide style="height:1rem; border:1px solid red;  box-sizing:border-box;">2</SwiperSlide>
      <SwiperSlide style="height:1rem; border:1px solid red;  box-sizing:border-box;">3</SwiperSlide> -->
    <!-- </Swiper> -->
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { Swiper as swiper, Mousewheel } from "swiper";
swiper.use(Mousewheel);
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      current: 0,
      list: [
        {
          image: require("@/assets/home/apply/1.png"),
          logo: require("@/assets/home/apply/logo1.png"),
          name: "优衣库",
          info: "优衣库引入RFID助力消费体验，降低90%人力。优衣库开设一家由RFID技术驱动的数字化门店，包括RFID试衣镜、RFID购物车、RFID自助结账等，全方位助力消费体验升级，还在仓库引入一套由机器负责服装品管、分货等工作的自动化系统，自动读取衣服上的RFID标签，为仓库减少90%的人力。",
        },
        {
          image: require("@/assets/home/apply/2.png"),
          logo: require("@/assets/home/apply/logo2.png"),
          name: "迪卡侬",
          info: "迪卡侬是较早引入RFID技术的一家，也是在应用方面最具代表性的品牌。据了解，迪卡侬是从2013年开始引入RFID技术的。经过多年的发展后，如今迪卡侬已经将RFID技术融入到仓储物流、零售和全渠道销售等各个环节中。",
        },
        {
          image: require("@/assets/home/apply/3.png"),
          logo: require("@/assets/home/apply/logo3.png"),
          name: "全棉时代",
          info: "全棉时代通过RFID实现有纺类商品单品级管理，实现从工厂、仓库到门店的全流程管理，大幅提高物流整体效率和数据准确性，提高销售收入，实现供应链数据彻底透明化，为第二阶段和第三阶段的数据采集和大数据分析提供及时、准确、干净的数据来源，为全棉时代实现数字化转型提供关键支撑。",
        },
      ],
      swiper: null,
    };
  },
  methods: {
    setSwiperInstance($event) {
      this.swiper = $event;
    },
    toLeft() {
      // this.current > 0 && this.current--
      this.swiper.slidePrev();
    },
    toRight() {
      // this.current < this.list.length - 1 && this.current++

      this.swiper.slideNext();
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  .container {
    background-image: url("~@/assets/home/apply/bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    //padding-bottom: 0.97rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .main {
    width: 100%;

    @if ($searchWidth<1100) {
      padding: 1rem 2rem 2rem 1.25rem;
      box-sizing: border-box;
      overflow: hidden;
      position: relative;

      &::v-deep {
        .swiper-initialized {
          overflow: initial !important;
        }
      }

      .l,
      .r {
        z-index: 11;
        position: absolute;
        top: 55%;
        width: 2.5rem;

        img {
          width: 100%;
          display: block;
        }
      }

      .l {
        left: 0;
      }

      .r {
        right: 0;
      }
    }
  }

  .box {
    @if ($searchWidth<1100) {
      margin: 1rem 0 0;
      width: 100%;
      display: flex;
      transition: all 0.2s;
    } @else {
      max-width: 13.2rem;
      margin: 0.43rem auto 0;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 0.4rem;
    }

    .item {
      background: linear-gradient(180deg, #fcffff 0%, #ffffff 100%);
      box-shadow: 0 0.04rem 0.11rem 0.03rem rgba(83, 177, 165, 0.23);
      border-radius: 0.5rem 0.5rem 0 0;
      transition: all 0.2s;

      @if ($searchWidth<1100) {
        // &:not(:first-child) {

        //   margin-left: 0.75rem;
        // }

        height: calc(100% - 0.34rem);
        margin-right: 0.75rem;
        // border: 1px solid red;
        padding: 0 0.75rem 0.34rem 0.75rem;
        flex-shrink: 0;
        // width: calc(100% - 0.75rem);
        // box-sizing: border-box;
      } @else {
        padding: 0 0.24rem 0.34rem 0.24rem;

        &:hover {
          box-shadow: 0 0.04rem 0.19rem 0.05rem rgba(83, 177, 165, 0.39);

          .image img {
            transform: scale(1.2);
          }

          .name {
            color: #5ec7b9;
          }
        }
      }
    }

    img {
      width: 100%;
      display: block;
      transition: all 0.2s;
    }

    .image {
      overflow: hidden;
      border-radius: 0.1rem 0.1rem 0 0;

      @if ($searchWidth<1100) {
        margin: 0 -0.75rem;
      } @else {
        margin: 0 -0.24rem;
      }
    }

    .logo {
      transform: translateY(-50%);

      @if ($searchWidth<1100) {
        width: 3rem;
        margin-bottom: -1.5rem;
      } @else {
        margin-bottom: -0.32rem;
        width: 0.68rem;
      }
    }

    .name {
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #333333;
      transition: all 0.2s;

      @if ($searchWidth<1100) {
        font-size: 1rem;
        margin: 0.5rem 0;
      } @else {
        font-size: 0.3rem;
        margin: 0.1rem 0;
      }
    }

    .info {
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #7a7a7a;

      @if ($searchWidth<1100) {
        font-size: 0.6rem;
        line-height: 1rem;
      } @else {
        font-size: 0.16rem;
        line-height: 0.25rem;
      }
    }
  }
}

@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
