<template>
  <!--  移动端-->
  <div
    class="mobile header-container-box"
    :class="{ 'header-show': show, 'header-hidden': !show }"
  >
    <div class="header-container" key="header-container">
      <div class="logo" @click="$router.push('/home')"></div>
      <div class="right">
        <!--        <div class="search"></div>-->
        <!--        <div class="i18n">En</div>-->
        <a
          class="sy"
          href="https://clothing.cirteklink.com/shop/"
          target="_blank"
        >
          服装门店系统试用
        </a>
        <div class="open-menu">
          <img
            v-show="!visible"
            @click="openMenu"
            :src="require('@/assets/menu-icon.png')"
          />
          <img
            v-show="visible"
            @click="visible = false"
            :src="require('@/assets/close.png')"
          />
        </div>
      </div>
    </div>
  </div>

  <common-mobile-nav v-model:visible="visible" :list="list"></common-mobile-nav>
  <!--  PC-->
  <div
    class="pc header-container-box"
    :class="{
      'header-show': show,
      'header-hidden': !show,
      'header-color-white': headerColor === 'white',
      'scroll-top': scrollTop,
      'hidden-bg': hiddenBg,
      'color-white': headerTopBtnWhite,
    }"
  >
    <div class="header-container" key="header-container" ref="header">
      <div class="logo" @click="$router.push('/home#banner')"></div>

      <div class="nav">
        <div class="nav-item" v-for="(item, index) in list" :key="index">
          <div
            class="nav-item-top"
            :class="{
              current: navItemTopCurrent(item),
              currentWhite: headerTopBtnWhite,
            }"
            @mouseover.stop="mouseoverHeader($event, true)"
          >
            <!--            !!item.child-->
            <span v-if="!item.to">{{ item.name }}</span>
            <template v-else>
              <!--              <a class="nav-item-btn pointer" href="/home#apply" v-if="item.md">-->
              <!--                {{ item.name }}</a-->
              <a class="nav-item-btn pointer" @click="to(item.to, item.md)">
                {{ item.name }}
              </a>
            </template>
          </div>
          <div class="child-box" @mouseover.stop>
            <header-product v-if="item.name === '产品与技术'" />
            <header-about v-if="item.name === '关于我们'" />
          </div>
        </div>
      </div>

      <div class="right">
        <a
          class="sy"
          href="https://clothing.cirteklink.com/shop/"
          target="_blank"
          >服装门店系统试用</a
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import headerProduct from "./header-product";
import headerAbout from "./header-about";
import commonMobileNav from "./common-mobile-nav";
import $ from "jquery";
export default {
  components: { headerProduct, headerAbout, commonMobileNav },
  data() {
    return {
      visible: false,
      show: true,
      scrollLog: 0,
      scrollTop: false,
      hiddenBg: false,
    };
  },
  computed: {
    ...mapGetters({
      list: "navs",
      headerColor: "headerColor",
      productName: "productName",
      headerTopBtnWhite: "headerTopBtnWhite",
    }),
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        $(window).scrollTop(0);
      }
    },
  },
  methods: {
    mouseoverHeader($event, bool) {
      $event.stopPropagation();
      if (bool) this.hiddenBg = true;
      else this.hiddenBg = false;
      // console.log(this.hiddenBg);
      return false;
    },
    openMenu() {
      this.visible = true;
    },
    navItemCurrent(parse) {
      if (this.$route.path === "/product") {
        return this.productName === parse.name;
      } else if (this.$route.path === "/serve") {
        return this.$route.fullPath === parse.to;
      } else {
        return this.$route.path === parse.to;
      }
    },
    navItemTopCurrent(parse) {
      if ([parse.to].includes(this.$route.fullPath)) {
        return true;
      }
      if (["/home"].includes(this.$route.path)) {
        let s = ["/home", "/home#undefined", "/home#other"];
        return s.includes(this.$route.fullPath) && parse.to === "/home#banner";
      } else {
        let child = parse.child;
        let index =
          child &&
          child.findIndex((item) => {
            // if (this.$route.path === "/product") {
            //   return this.productName === item.name;
            // } else {
            // console.log(item.to, this.$route.path);
            let res =
              item.to === this.$route.path ||
              item.to + "/" === this.$route.path;
            if (!res && item.children) {
              res =
                item.children.findIndex(
                  (item) => item.to === this.$route.path
                ) !== -1;
            }
            return res;
            // }
          });
        return child && index !== -1;
      }
    },
    scrollFn() {
      let { scrollLog } = this;
      let scroll = document.documentElement.scrollTop;
      if (scroll > scrollLog) {
        this.show = false;
      } else {
        this.show = true;
      }
      this.scrollLog = scroll;
      this.scrollTop = document.documentElement.scrollTop > 400;
      // this.show = document.documentElement.scrollTop <= 300;
    },
    to(url, md) {
      if (md) window.location = url;
      else this.$router.push(url);
    },
  },

  mounted() {
    document.addEventListener("scroll", this.scrollFn);
    document.addEventListener("mouseover", () => {
      this.hiddenBg = false;
    });
  },
  unmounted() {
    window.removeEventListener("scroll", this.scrollFn);
  },
  provide() {
    return {
      to: this.to,
    };
  },
};
</script>
<style lang="scss" scoped>
$headerHeight: 1rem;
$itemWidth: 1.7rem;

@keyframes itemTopHover {
  0% {
    top: 0.25rem;
    opacity: 0;
  }

  100% {
    top: 0.25rem;
    opacity: 1;
  }
}

@keyframes header-hidden {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    z-index: -1;
  }
}

@keyframes header-show {
  0% {
    opacity: 0;
    z-index: 999;
  }

  100% {
    opacity: 1;
  }
}

@media (min-width: 1100px) {
  .header-container-box {
    &.pc {
      display: flex;
    }

    &.mobile {
      display: none;
    }
  }
}

@media (max-width: 1100px) {
  $mobileHeight: 3.2rem;

  .header-container-box {
    &.pc {
      display: none;
    }

    &.mobile {
      display: flex;
      height: $mobileHeight;
      background: #fff !important;

      .logo {
        width: 5.75rem;
        height: $mobileHeight;

        background-image: url("~@/assets/m-logo.png");
      }

      .right {
        height: $mobileHeight;

        .sy {
          width: auto;
          border-radius: 1.35rem;
          height: 1.35rem;
          line-height: 1.35rem;
          font-size: 0.6rem;
          padding: 0 0.55rem;

          &:before {
            border-radius: 1rem;
            animation: hvr-ripple-out 2s infinite;
          }
        }

        .open-menu {
          width: 1rem;
          display: flex;
          margin-left: 1rem;

          img {
            width: 100%;
          }
        }
      }
    }
  }
}

/*
通用
*/
.pointer {
  cursor: pointer;
}

.header-container-box {
  box-sizing: border-box;
  padding: 0 0.71rem;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  transition: all 0.4s;

  //background: #fff;
  &.header-color-white {
    background: rgba(0, 0, 0, 0.45);
  }

  &:not(.header-color-white) {
    background: #fff;
  }

  box-shadow: 0 0.02rem 0.2rem 0 #e5e6e8;
  color: #333;

  &:not(:hover):not(.scroll-top) {
    background: none;
    box-shadow: none;

    .nav-item-top {
      //background: none;
      &:not(.current) {
        color: #333;
      }

      &.currentWhite {
        color: #333;
        //&:after {
        //  background-color: #fff;
        //}
      }
    }
  }

  &.hidden-bg {
    //&:not(.color-white) {
    background: rgba(0, 0, 0, 0.45);
    //}
    //&.color-white {
    //  background: rgba(0, 0, 0, 0.45);
    //}
    color: #fff;

    .logo {
      background-image: url("~@/assets/logo.png");
    }
  }

  &.header-color-white {
    color: #fff;

    &:not(.color-white):not(.none-bg) {
      background: rgba(0, 0, 0, 0.45) !important;
    }

    .logo {
      background-image: url("~@/assets/logo.png");
    }

    .nav {
      .nav-item-top {
        color: #fff !important;
      }
    }
  }

  //&.header-color-white:not(:hover):not(.scroll-top) {
  //  background: none;
  //  .logo {
  //    background-image: url("~@/assets/logo.png");
  //  }
  //  .nav-item-top {
  //    background: none;
  //    &:not(.current) {
  //      color: #fff;
  //    }
  //    &.currentWhite {
  //      color: #fff;
  //      &:after {
  //        background-color: #fff;
  //      }
  //    }
  //  }
  //  .nav-item-children-box {
  //    opacity: 0;
  //  }
  //  .right {
  //    .i18n {
  //      color: #fff;
  //    }
  //  }
  //}

  &.header-hidden {
    animation: header-hidden 0.5s forwards !important;
  }

  &.header-hidden {
    animation: header-hidden 0.5s forwards !important;
  }

  &.header-show {
    animation: header-show 0.5s forwards !important;
  }
}

.header-container {
  max-width: 17.8rem;
  width: 100%;
  //transition: all 0.3s;
  height: $headerHeight;
  display: flex;
  justify-content: space-between;

  .logo {
    flex-shrink: 0;
    width: 4.46rem;
    height: $headerHeight;
    display: flex;
    align-items: center;
    background-image: url("~@/assets/logo1.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;

    img {
      width: 100%;
    }
  }

  .nav {
    display: flex;
    justify-content: center;
    width: 8.66rem;

    .nav-item {
      flex-shrink: 0;

      .nav-item-top {
        padding: 0 0.24rem;
        height: $headerHeight;
        line-height: $headerHeight;
        font-size: 0.18rem;
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        font-weight: normal;
        //color: #333333;
        position: relative;
        text-align: center;
        z-index: 1000;
        //transition: all 0.3s;

        &.current {
          color: #5ec7b9 !important;

          &:after {
            content: "";
            position: absolute;
            width: 0.08rem;
            height: 0.08rem;
            background: #5ec7b9;
            border-radius: 50%;
            left: 50%;
            transform: translateX(-50%);
            top: 0.2rem;
            animation: itemTopHover 0.5s;
          }
        }
      }

      &:hover {
        .child-box {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }

    .child-box {
      height: auto;
      width: 100%;
      position: absolute;
      left: 0;
      top: 1rem;
      opacity: 0;
      pointer-events: none;
      transition: all 0.2s;
      background: #fafbfc;
      box-shadow: 0px 0.02rem 0.05rem 0px rgba(144, 178, 174, 0.58);
    }
  }

  .right {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    height: $headerHeight;

    .i18n {
      cursor: pointer;
      font-size: 0.18rem;
    }

    .sy {
      width: 2rem;
      height: 0.42rem;
      border: 1px solid #5ec7b9;
      border-radius: 0.23rem;
      font-size: 0.18rem;
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: 500;
      color: #fff;
      line-height: 0.42rem;
      text-align: center;
      margin-left: 0.2rem;
      cursor: pointer;
      background: #5ec7b9;
      position: relative;
      text-decoration: none;

      &:before {
        content: "";
        position: absolute;
        border: #5ec7b9 solid 0.02rem;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 0.4rem;
        animation: hvr-ripple-out 2s infinite;
      }
    }
  }
}
</style>
